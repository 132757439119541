<template>
  <div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <v-card class="elevation-0 overflow-hidden mt-5">
      <v-toolbar flat class="mt-2">
        <!-- <v-select
          label="Duration"
          dense
          outlined
          item-text="text"
          item-value="value"
          :items="reportItems"
          style="max-width: 170px"
          v-model="reportStatus"
          class=""
        ></v-select> -->
        <v-dialog
          ref="dialog"
          v-model="modal"
          color="#f4813f"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              dense
              outlined
              class="ml-2"
              persistent-hint
              v-show="reportStatus == 'daily'"
              label="Filter by Date"
              color="#f4813f"
              append-icon="mdi-calendar"
              @click:append="date ? getcal() : getcal()"
              v-bind="attrs"
              readonly
              v-on="on"
              style="max-width: 200px"
            >
            </v-text-field>
          </template>
          <v-date-picker
            :min="minmonth"
            :max="new Date().toISOString().substr(0, 10)"
            v-model="date"
            color="#f4813f"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="#f4813f" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="#f4813f" @click="$refs.dialog.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <!-- <v-select
          v-model="Memberstatus"
          :items="Memberitemstatus"
          v-if="reportStatus == 'monthly'"
          label="Member Status"
          style="max-width: 200px"
          item-text="text"
          item-value="value"
          outlined
          dense
          square
          class="ml-2"
        ></v-select> -->
        <!-- <v-select
          v-if="Memberstatus == 'ACTIVE' && reportStatus == 'monthly'"
          v-model="select_members"
          :items="selectmembers"
          label="Select Member"
          style="max-width: 200px"
          item-text="text"
          item-value="value"
          outlined
          dense
          square
          class="ml-2"
        ></v-select>
        <v-select
          v-if="Memberstatus == 'DEACTIVE' && reportStatus == 'monthly'"
          v-model="select_members_inactive"
          :items="selectmembers_inactive"
          label="Select Member"
          style="max-width: 200px"
          item-text="text"
          item-value="value"
          outlined
          dense
          square
          class="ml-2"
        ></v-select> -->
        <v-select
          label="Filter by Year"
          v-model="selectedYear"
          :items="filteredYears"
          v-if="reportStatus == 'monthly'"
          item-text="text2"
          item-value="value2"
          style="max-width: 200px"
          outlined
          dense
          square
          class="ml-2"
          @change="month_get"
        />
        <v-select
          label="Filter by Month"
          v-model="selectedMonth"
          :items="formattedMonthItems"
          v-if="reportStatus == 'monthly'"
          item-text="text"
          item-value="value"
          style="max-width: 200px"
          outlined
          dense
          square
          class="ml-2"
        />
        <!-- <v-select
          label="Export Type"
          v-model="proofReport"
          :items="proofReportitems"
          item-text="text"
          item-value="value"
          style="max-width: 200px"
          class="ml-2"
          outlined
          dense
        ></v-select> -->
        <v-btn
          @click="send_monthly_report()"
          :loading="isLoading"
          small
          class="ml-4 mt-n4"
          color="primary white--text"
          >Generate Report</v-btn
        >
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#D97D54"
              class="gradient-bg white--text ml-4 mt-n4"
              text
              small
              v-on="on"
              v-bind="attrs"
              depressed
              @click="get_list()"
            >
              <v-icon class="pr-2">mdi-refresh</v-icon> Refresh
            </v-btn>
          </template>
          <span class="white--text">Refresh</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow"
          dense
          :headers="tableexportdata"
          :items="exporttable"
          :options.sync="pagination"
          :items-per-page="10"
          :footer-props="{
            'items-per-page-options': [10, 15],
          }"
          :fixed-header="fixed"
          loading-text="Loading... Please wait"
          :loading="isLoadingexport"
        >
          <template v-slot:no-data>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No Data available.
            </v-alert>
          </template>
          <template v-slot:[`item.report_generated_for`]="{ item }">
            <div
              class="overflow ml-5"
              v-if="item.report_generated_for == undefined"
            >
              -
            </div>
            <div v-else>
              {{ item.report_generated_for }}
            </div>
          </template>

          <template v-slot:[`item.report_type`]="{ item }">
            <div class="overflow" v-if="item.report_type == 'DAILY_PRESENT'">
              <span>Daily Present</span>
            </div>
            <div class="overflow" v-if="item.report_type == 'YEARLY'">
              <span>Yearly </span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'DAILY_PROOF_OF_WORK'"
            >
              <span v-if="item.report_s3_key != undefined">
                Daily Proof Of Work ({{
                  item.report_s3_key.split(".").pop().split("?")[0] == "pdf"
                    ? "PDF"
                    : "Excel"
                }}
                )</span
              >
              <span v-else> Daily Proof Of Work</span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'MONTHLY_PROOF_OF_WORK'"
            >
              <span v-if="item.report_s3_key != undefined">
                Monthly Proof Of Work ({{
                  item.report_s3_key.split(".").pop().split("?")[0] == "pdf"
                    ? "PDF"
                    : "Excel"
                }}
                )
              </span>
              <span v-else> Monthly Proof Of Work </span>
            </div>

            <div
              class="overflow"
              v-if="item.report_type == 'MONTHLY_SCANS_COUNT'"
            >
              <span>Monthly Scan Count </span>
            </div>

            <div class="overflow" v-if="item.report_type == 'MONTHLY_SWIPES'">
              <span>Monthly Swipes</span>
            </div>
            <div class="overflow" v-if="item.report_type == 'MONTHLY_DURATION'">
              <span>Monthly Duration </span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'MONTHLY_PRESENT_ABSENT_REPORT'"
            >
              <span>Monthly Present And Absent </span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'MONTHLY_NORMALIZE'"
            >
              <span>Monthly Normalize </span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'WEEKLY_SCANS_COUNT'"
            >
              <span>Weekly Scan Count </span>
            </div>

            <div class="overflow" v-if="item.report_type == 'WEEKLY_SWIPES'">
              <span>Weekly Swipes</span>
            </div>
            <div class="overflow" v-if="item.report_type == 'WEEKLY_DURATION'">
              <span>Weekly Duration </span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'WEEKLY_PRESENT_ABSENT_REPORT'"
            >
              <span>Weekly Present And Absent </span>
            </div>
            <div class="overflow" v-if="item.report_type == 'WEEKLY_NORMALIZE'">
              <span>Weekly Normalize </span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'WEEKLY_LEAVE_REPORT'"
            >
              <span>Weekly Leave </span>
            </div>

            <div class="overflow" v-if="item.report_type == 'DAILY_ABSENT'">
              <span>Daily Absent</span>
            </div>
            <!-- <div class="overflow" v-if="item.report_type == 'MONTHLY_SCANS_COUNT'">
                <span>Monthly Scans Leave</span>
              </div> -->
            <div
              class="overflow"
              v-if="item.report_type == 'MONTHLY_LEAVE_REPORT'"
            >
              <span>Monthly Leave</span>
            </div>
            <div class="overflow" v-if="item.report_type == 'LEAVE_REPORT'">
              <span>Daily Leave</span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'MONTHLY_EARLY_OUT'"
            >
              <span>Monthly Early Out</span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'MONTHLY_LATE_ARRIVAL'"
            >
              <span>Monthly Late Arrival</span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'MONTHLY_SHORT_DURATION'"
            >
              <span>Monthly Short Duration</span>
            </div>
            <div
              class="overflow"
              v-if="item.report_type == 'MONTHLY_DATA_CORRECTION'"
            >
              <span>Monthly Data Correction</span>
            </div>
          </template>
          <template v-slot:[`item.report_user_type`]="{ item }">
            <div class="overflow" v-if="item.report_user_type == 'ALL_USER'">
              <span>All</span>
            </div>
            <div
              class="overflow"
              v-else-if="item.report_user_type == 'ALL_USERS'"
            >
              <span>All</span>
            </div>
            <div class="overflow" v-else-if="item.report_user_type == 'ALL'">
              <span>All</span>
            </div>
            <div
              class="overflow"
              v-else-if="item.report_user_type == 'MONTHLY__ALL_USERS'"
            >
              <span>All</span>
            </div>
            <div
              class="overflow"
              v-else-if="item.report_user_type == 'MONTHLY_ALL_USERS'"
            >
              <span>All</span>
            </div>
            <div class="overflow" v-else>
              <span>{{ item.report_user_type }}</span>
            </div>
          </template>
          <template v-slot:[`item.report_generated_on`]="{ item }">
            <span
              class="overflow"
              v-text="get_date(item.report_generated_on)"
            ></span>
          </template>
          <template v-slot:[`item.reported_by`]="{ item }">
            <div class="overflow" v-if="item.reported_by != undefined">
              {{ item.reported_by }}
            </div>
            <div v-else>{{ $store.getters.get_useremail }}</div>
          </template>
          <template v-slot:[`item.report_s3_key`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <a
                  v-on="on"
                  v-show="item.report_s3_key != undefined"
                  @click="download_invoice(item)"
                >
                  <v-icon v-on="on" small class="primary--text mr-1"
                    >mdi-download
                  </v-icon>
                </a>
                <span v-show="item.report_s3_key == undefined" class="ml-2"
                  >-</span
                >
              </template>

              <span
                v-if="
                  item.report_s3_key != null || item.report_s3_key != undefined
                "
                class="white--text"
                >Download</span
              >
            </v-tooltip>
          </template>
          <template v-slot:[`item.report_generation_status`]="{ item }">
            <span
              v-if="item.report_generation_status == 'COMPLETED'"
              class="green--text"
              >{{ item.report_generation_status }}</span
            >
            <v-tooltip bottom color="primary" v-else>
              <template v-slot:activator="{ on }">
                <span class="orange--text" v-on="on">{{
                  item.report_generation_status
                }}</span>
              </template>
              <span class="white--text">Refresh </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { daily_report, monthReport } from "@/graphql/mutations.js";
import { getReports } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { ListAllUsers } from "@/graphql/queries.js";
import SnackBar from "@/components/SnackBar.vue";
export default {
  components: {
    SnackBar,
  },
  data() {
    return {
      select_members: "",
      fixed: true,
      isLoadingexport: false,
      height: 0,
      isLoading: false,
      SnackBarComponent: {},
      selectedYear: new Date().getFullYear(),
      modal: false,
      selectedDate: "",
      minmonth: "",
      date: new Date().toISOString().substr(0, 10),
      select_members_inactive: "",
      reportItems: [
        { text: "Daily", value: "daily" },
        { text: "Monthly", value: "monthly" },
      ],
      reportStatus: "monthly",
      selectmembers: [],
      Memberstatus: "ACTIVE",
      Memberitemstatus: [
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "INACTIVE", value: "DEACTIVE" },
      ],
      selectmembers_inactive: [],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      formattedMonthItems: [],
      selectedMonth: "",
      exporttable: [],
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      proofReport: "1",
      proofReportitems: [
        { text: "PDF", value: "1" },
        { text: "Excel", value: "2" },
      ],
      tableexportdata: [
        { text: "Report Type", value: "report_type" },
        { text: "User Type", value: "report_user_type" },
        { text: "Generated For", value: "report_generated_for" },
        { text: "Generated on", value: "report_generated_on" },
        { text: "Generated By", value: "reported_by" },
        { text: "Status", value: "report_generation_status" },
        { text: "Report", value: "report_s3_key" },
      ],
      exporttable: [],
    };
  },
  async mounted() {
    this.get_list();
    this.height = window.innerHeight - 250;
    this.selectedDate = this.get_date(
      this.$store.getters.get_org_details[0].organisation_created_on
    );
    //  this.selectedDate='03/02/2024, 03:35:44 PM';
    (this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    )),
      await this.userlist();
    await this.month_get();
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    filteredYears() {
      const currentYear = new Date().getFullYear();
      var dateComponents = this.selectedDate.split(/[\/,\s:]+/);
      // Extracting components
      var month = parseInt(dateComponents[0], 10) - 1; // Month is zero-indexed in JavaScript Date
      var day = parseInt(dateComponents[1], 10);
      var year = parseInt(dateComponents[2], 10);
      var hour = parseInt(dateComponents[3], 10);
      var minute = parseInt(dateComponents[4], 10);
      var second = parseInt(dateComponents[5], 10);
      var period = dateComponents[6]; // "AM" or "PM"

      // Adjust hour based on AM/PM
      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }

      var date = new Date(year, month, day, hour, minute, second);

      const selectedDateYear = date.getFullYear();
      const years = [];

      for (let year = selectedDateYear; year <= currentYear; year++) {
        years.push(year);
      }
      return years;
    },
  },
  methods: {
    async download_invoice(url) {
      var extension = "";
      if (url.report_type === "MONTHLY_PROOF_OF_WORK") {
        extension =
          url.report_s3_key.split(".").pop().split("?")[0] == "pdf"
            ? ".pdf"
            : ".xlsx";
      } else {
        extension = ".xlsx";
      }
      var fileName =
        url.report_type +
        "_" +
        this.get_date(url.report_generated_on) +
        extension;
      axios({
        url: url.report_s3_key,
        method: "GET",
        responseType: "blob", // Set the response type to blob
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          // Use the FileSaver library to save the file
          FileSaver.saveAs(blob, fileName);
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    },
    async get_list() {
      this.isLoadingexport = true;
      this.overlay = true;
      try {
        await API.graphql(
          graphqlOperation(getReports, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
                report_type :"MONTHLY_DATA_CORRECTION",
            },
          })
        ).then((res) => {
     
          this.exporttable = [];
          this.exporttable = JSON.parse(
            res.data.getReports
          ).data;
          // console.log(this.exporttable);
          this.overlay = false;
          this.isLoadingexport = false;
          // console.log(this.exporttable);
        });
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.overlay = false;
        this.isLoadingexport = false;
      }
    },
    send_monthly_report() {
      //   if (
      //     this.reportStatus == "monthly" &&
      //     this.Memberstatus == "ACTIVE" &&
      //     this.select_members == ""
      //   ) {
      //     this.SnackBarComponent = {
      //       SnackbarVmodel: true,
      //       SnackbarColor: "red",
      //       Top: true,
      //       SnackbarText: "Select the Member",
      //     };
      //   } else if (
      //     this.reportStatus == "monthly" &&
      //     this.Memberstatus == "DEACTIVE" &&
      //     this.select_members_inactive == ""
      //   ) {
      //     this.SnackBarComponent = {
      //       SnackbarVmodel: true,
      //       SnackbarColor: "red",
      //       Top: true,
      //       SnackbarText: "Select the Member",
      //     };
      //   } else {
      //     if (this.reportStatus == "monthly") {
      //       this.getMonthlyreport();
      //     } else {
      //       this.getDailyreport();
      //     }
      //   }
      this.getMonthlyreport();
    },
    month_get() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonthIndex = currentDate.getMonth();

      let newMonthItems = [];
      const month = this.selectedDate.match(/^(\d{2})\//)[1];

      if (this.selectedYear < currentYear) {
        newMonthItems = this.monthNames.slice();
      } else if (this.selectedYear === currentYear) {
        newMonthItems = this.monthNames.slice(0, currentMonthIndex + 1);
      } else {
        newMonthItems = this.monthNames.slice();
      }

      var monthItems = newMonthItems.slice();

      this.formattedMonthItems = monthItems.map((month, index) => ({
        text: month,
        value: (index + 1).toString().padStart(2, "0"),
      }));
      this.selectedMonth = (currentMonthIndex + 1).toString().padStart(2, "0");
      if (this.selectedYear == new Date(this.selectedDate).getFullYear()) {
        const startIndex = this.formattedMonthItems.findIndex(
          (item) => item.value === month
        );

        const filteredArray =
          startIndex !== -1 ? this.formattedMonthItems.slice(startIndex) : [];

        this.formattedMonthItems = [];
        this.formattedMonthItems = filteredArray;
        this.selectedMonth = month;
      }

      this.$forceUpdate();
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    async getMonthlyreport() {
      this.isLoading = true;

      try {
        let result = await API.graphql(
          graphqlOperation(monthReport, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              user_email_id: [],
              offsets: new Date().getTimezoneOffset() * -60000,
              user_status: "ACTIVE",
              dynamo_month:
                this.selectedYear.toString() + "-" + this.selectedMonth,
              type_report: "DATA_CORRECTION",
              user_id: "ALL",
              generated_by:
                this.$store.getters.get_org_user_details[0].user_email_id,
              location_id: "ALL",
              home_location_id: "ALL",
              exp_type: undefined,
            },
          })
        );
        var response = JSON.parse(result.data.monthReport);
        if (response.Status == "SUCCESS") {
          this.overlay = false;
          this.isLoading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: response.Message,
          };
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: response.Message,
          };
        }
        this.overlay = false;
        this.isLoading = false;

        this.get_list();
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    async getDailyreport() {
      this.isLoading = true;
      try {
        let result = await API.graphql(
          graphqlOperation(daily_report, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              user_email_id: [],
              offsets: new Date().getTimezoneOffset() * -60000,
              attendence: "DATA_CORRECTION",
              dynamo_date: this.computedDateFormatted,
              generated_by:
                this.$store.getters.get_org_user_details[0].user_email_id,
              exp_type: undefined,
            },
          })
        );
        var response = JSON.parse(result.data.daily_report);
        if (response.Status == "SUCCESS") {
          this.overlay = false;
          this.isLoading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: response.Message,
          };
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: response.Message,
          };
        }

        this.overlay = false;
        this.isLoading = false;

        this.get_list();
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    async userlist() {
      try {
        let result = await API.graphql(
          graphqlOperation(ListAllUsers, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );

        this.userList = result.data.ListAllUsers.ActiveMembers;
        this.userinactive = result.data.ListAllUsers.InActivemembers;
        var self = this;
        self.userinactive.forEach(function (item) {
          self.selectmembers_inactive.push({
            text: item.user_name,
            value: item.user_id,
          });
        });

        self.userList.forEach(function (item) {
          self.selectmembers.push({
            text: item.user_name,
            value: item.user_id,
          });
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
      }
    },
  },
};
</script>

<style>
</style>